import React, { useState, useEffect } from "react";

const App = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [currentColor, setCurrentColor] = useState("white");
  const [cycleCount, setCycleCount] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isRunning) {
      interval = setInterval(() => {
        setCurrentColor((prevColor) => (prevColor === "white" ? "black" : "white"));
        setCycleCount((prevCount) => prevCount + 1);
      }, 3000); // 3秒ごとに切り替え
    }

    // 点滅が8回に達したら停止
    if (cycleCount >= 8) {
      clearInterval(interval);
      setIsRunning(false);
    }

    return () => clearInterval(interval); // クリーンアップ
  }, [isRunning, cycleCount]);

  const handleStart = () => {
    setIsRunning(true);
    setCycleCount(0); // 点滅回数をリセット
    setCurrentColor("white"); // 初期色をリセット
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* 画像を画面の上部に表示 */}
      {!isRunning && cycleCount < 8 && (
        <img
          src="https://test.pupil.best/static/images/start-banner.png" // 絶対パスを指定
          alt="Start Banner"
          style={{
            position: "absolute",
            top: "20px",
          }}
        />
      )}

      {/* メイン表示（スタートボタン、点滅四角、Test Completeメッセージ） */}
      <div
        style={{
          width: "10cm",
          height: "8cm",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* スタートボタン */}
        {!isRunning && cycleCount < 8 && (
          <button
            onClick={handleStart}
            style={{
              padding: "10px 20px",
              fontSize: "18px",
              backgroundColor: "green",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Start
          </button>
        )}

        {/* 点滅の表示 */}
        {isRunning && cycleCount < 8 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: currentColor,
              border: currentColor === "black" ? "5px solid white" : "none",
            }}
          ></div>
        )}

        {/* 点滅完了時の表示 */}
        {cycleCount >= 8 && (
          <h1
            style={{
              color: "white",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            Test Complete
          </h1>
        )}
      </div>
    </div>
  );
};

export default App;

